import React, { Fragment } from "react"
import { compose } from "recompose"
import Layout from "../components/layout"
import { AuthUserContext, withAuthorization } from "../components/Session"
import { loadStripe } from "@stripe/stripe-js"
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js"
import { v1 as uuidv1 } from "uuid"
import { CountryDropdown, RegionDropdown } from "react-country-region-selector"
import { navigate } from "gatsby"
import LoadingOverlay from "react-loading-overlay"
import CheckoutPricing from "../components/CheckoutPricing"

// const config = {
//   stripeKey: process.env.STRIPE_PUBLISHABLE_KEY,
// }

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props)

    let customerInfo = props.location.location.location.state.customerInfo

    // console.log("customerInfo", customerInfo)

    this.state = {
      country: "",
      region: "",
      loading: false,
      customerInfo: customerInfo,
      error: true,
    }
  }

  handleSubmit = (id, email, price) => async (event) => {
    event.preventDefault()

    // console.log("handleSubmit")

    const { stripe, elements } = this.props
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }
    this.setState({ loading: true })

    const userInfo = {
      name: event.target.name.value,
      id: id,
      email: email,
      phone: event.target.phone.value,
      address: {
        city: event.target.city.value,
        country: this.state.country,
        line1: event.target.address1.value,
        line2: event.target.address2.value,
        state: event.target.state.value,
        postalCode: event.target.postalCode.value,
      },
    }

    const customerInfo = this.state.customerInfo

    let result = {}
    result.error = false

    if (price > 0) {
      result = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          // Include any additional collected billing details.
          name: event.target.name.value,
        },
      })
    }

    this.stripePaymentMethodHandler(result, userInfo, customerInfo)
  }

  stripePaymentMethodHandler(result, userInfo, customerInfo) {
    const that = this
    if (result.error) {
      that.setState({ loading: false })
      // Show error in payment form
    } else {
      // Otherwise send paymentMethod.id to your server (see Step 4)
      let paymentMethodId =
        "paymentMethod" in result ? result.paymentMethod.id : null
      fetch("/api/payment", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          payment_method_id: paymentMethodId,
          stripeIdempotency: uuidv1(),
          userInfo: userInfo,
          customerInfo: customerInfo,
        }),
      }).then(function (result) {
        // Handle server response (see Step 4)
        result.json().then(function (json) {
          that.handleServerResponse(json, userInfo, customerInfo)
        })
      })
    }
  }

  handleServerResponse(response, userInfo, customerInfo) {
    const that = this
    const { stripe } = this.props
    if (response.error) {
      that.setState({ loading: false })
      // Show error from server on payment form
      alert(response.error.toString())
    } else if (response.requires_action) {
      that.setState({ loading: false })
      // Use Stripe.js to handle required card action
      stripe
        .handleCardAction(response.payment_intent_client_secret)
        .then((result) => {
          that.setState({ loading: true })
          that.handleStripeJsResult(result, userInfo, customerInfo)
        })
        .catch((err) => {
          alert(err)
        })
    } else {
      // success!
      that.setState({ loading: false })
      // console.log("success")
      // Show success message
      navigate("/thankyou", { state: customerInfo })
    }
  }

  handleStripeJsResult(result, userInfo, customerInfo) {
    const that = this
    if (result.error) {
      alert(result.error.toString())
    } else {
      // The card action has been handled
      // The PaymentIntent can be confirmed again on the server
      fetch("/api/payment", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          payment_intent_id: result.paymentIntent.id,
          userInfo: userInfo,
          customerInfo: customerInfo,
        }),
      })
        .then(function (confirmResult) {
          return confirmResult.json()
        })
        .then((value) => {
          that.handleServerResponse(value, userInfo, customerInfo)
        })
    }
  }

  selectCountry(val) {
    this.setState({ country: val })
  }

  selectRegion(val) {
    this.setState({ region: val })
  }

  render() {
    const { stripe } = this.props
    const { country, region } = this.state

    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <Fragment>
            <MyLoader active={this.state.loading}>
              <form
                onSubmit={this.handleSubmit(
                  authUser.uid,
                  authUser.email,
                  this.getPricing(
                    this.state.customerInfo.product,
                    this.state.customerInfo.numUsers,
                    authUser
                  )
                )}
              >
                <div className="row">
                  <div className="col-md-8 col-lg-8 card mb-3">
                    <h5 className="card-title mt-3">Billing Information</h5>
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="form-control"
                            placeholder="Full Name"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <input
                            type="tel"
                            name="phone"
                            id="phone"
                            className="form-control"
                            placeholder="Phone Number"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name="address1"
                            id="address1"
                            className="form-control"
                            placeholder="Address Line 1"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name="address2"
                            id="address2"
                            className="form-control"
                            placeholder="Address Line 2"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name="city"
                            id="city"
                            className="form-control"
                            placeholder="City"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <CountryDropdown
                            name="country"
                            id="country"
                            className="form-control"
                            value={country}
                            onChange={(val) => this.selectCountry(val)}
                            valueType={"short"}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <RegionDropdown
                            country={country}
                            value={region}
                            onChange={(val) => this.selectRegion(val)}
                            name="state"
                            id="state"
                            className="form-control"
                            countryValueType="short"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name="postalCode"
                            id="postalCode"
                            className="form-control"
                            placeholder="Postal Code"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    {this.getPricing(
                      this.state.customerInfo.product,
                      this.state.customerInfo.numUsers,
                      authUser
                    ) !== 0 && (
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <div className="form-group border py-2 px-2 rounded">
                            <CardElement
                              options={{
                                style: {
                                  base: {
                                    fontSize: "16px",
                                    color: "#424770",
                                    backgroundColor: "#ffffff",
                                    "::placeholder": {
                                      color: "#aab7c4",
                                    },
                                  },
                                  invalid: {
                                    color: "#9e2146",
                                  },
                                },
                              }}
                              onChange={(event) => {
                                this.onStripeElementChange(event)
                              }}
                            />
                            <span id="card-errors" role="alert"></span>
                          </div>
                          <button
                            type="submit"
                            className="default-btn btn-block mb-3 text-center"
                            disabled={!stripe || this.state.loading}
                          >
                            <i className="bx bxs-dollar-circle relative"></i>
                            Order
                            <span></span>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-md-4 col-lg-4 mb-3">
                    <div className="col-md-12 col-lg-12 card">
                      <h5 className="card-title mt-3">Price Info</h5>
                      <div className="mb-3">
                        <CheckoutPricing
                          product={this.state.customerInfo.product}
                          numUsers={this.state.customerInfo.numUsers}
                          user={authUser}
                          state={this.state.customerInfo.address.state}
                          parentCallback={this.callbackFunction}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </MyLoader>
          </Fragment>
        )}
      </AuthUserContext.Consumer>
    )
  }

  isCurrentUser(userInfo) {
    if ("isCurrentCustomer" in userInfo) {
      if (userInfo["isCurrentCustomer"]) {
        return true
      }
    }
    return false
  }

  getDiscountPercent(userInfo) {
    if ("discountPercent" in userInfo) {
      return userInfo["discountPercent"] * 0.01
    }
    return 0.0
  }

  getProrateAmount(userInfo) {
    if ("prorateAmount" in userInfo) {
      return userInfo["prorateAmount"]
    }
    return 0.0
  }

  getBasePrice(product, numUsers, isCurrentUser) {
    const priceList = {
      hass: {
        1: 1800,
        2: 1800,
        3: 2520,
        4: 3240,
        5: 3960,
        6: 4400,
        7: 4840,
        8: 5280,
        9: 5720,
        10: 6020,
        11: 6320,
        12: 6620,
        13: 6920,
        14: 7220,
        15: 7520,
        16: 7820,
        17: 8120,
        18: 8420,
        19: 8720,
        20: 9020,
      },
      hassHouse: {
        1: 900,
        2: 900,
        3: 1260,
        4: 1620,
        5: 1980,
        6: 2200,
        7: 2420,
        8: 2640,
        9: 2860,
        10: 3010,
        11: 3160,
        12: 3310,
        13: 3460,
        14: 3610,
        15: 3760,
        16: 3910,
        17: 4060,
        18: 4210,
        19: 4360,
        20: 4510,
      },
      coosa: {
        1: 1800,
        2: 1800,
        3: 2520,
        4: 3240,
        5: 3960,
        6: 4400,
        7: 4840,
        8: 5280,
        9: 5720,
        10: 6020,
        11: 6320,
        12: 6620,
        13: 6920,
        14: 7220,
        15: 7520,
        16: 7820,
        17: 8120,
        18: 8420,
        19: 8720,
        20: 9020,
      },
    }

    if (numUsers > 20) {
      let additionalUsersPrice = 0
      let additionalUsers = numUsers - 20
      if (product == "hassHouse") {
        additionalUsersPrice = additionalUsers * 150
      } else {
        additionalUsersPrice = additionalUsers * 300
      }
      if (isCurrentUser) {
        //this brings us to renewal price 50% off base
        return (priceList[product][20] + additionalUsersPrice) * 0.5
      }
      return priceList[product][20] + additionalUsersPrice
    }

    if (isCurrentUser) {
      //this brings us to renewal price 50% off base
      return priceList[product][parseInt(numUsers)] * 0.5
    }
    return priceList[product][parseInt(numUsers)]
  }

  getPricing(product, numUsers, userInfo) {
    if (product === "" || numUsers === "") {
      return null
    }

    let basePrice = this.getBasePrice(
      product,
      numUsers,
      this.isCurrentUser(userInfo)
    )
    let calculatedPrice = basePrice

    //checks for user discount (often 20% for old users) and applies it
    let discountPercent = this.getDiscountPercent(userInfo)
    calculatedPrice *= 1 - discountPercent

    let prorateAmount = this.getProrateAmount(userInfo)
    calculatedPrice -= prorateAmount

    if (calculatedPrice < 0) {
      calculatedPrice = 0
    }

    return calculatedPrice
  }

  onStripeElementChange(event) {
    // console.log(event)
    let displayError = document.getElementById("card-errors")
    if (event.error) {
      displayError.textContent = event.error.message
      this.state.error = true
    } else {
      displayError.textContent = ""
      this.state.error = false
    }
    // console.log("thiserroe", this.state.error)
  }
}

function MyLoader({ active, children }) {
  return (
    <LoadingOverlay
      active={active}
      spinner
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(255, 255, 255, 0.5)",
        }),
        spinner: (base) => ({
          ...base,
          width: "100px",
          "& svg circle": {
            stroke: "rgba(199, 59, 27, 1)",
          },
        }),
      }}
    >
      {children}
    </LoadingOverlay>
  )
}

const InjectedCheckoutForm = (location) => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <CheckoutForm elements={elements} stripe={stripe} location={location} />
      )}
    </ElementsConsumer>
  )
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY)

const condition = (authUser) => !!authUser

const ProtectedCheckoutForm = compose(withAuthorization(condition, "checkout"))(
  InjectedCheckoutForm
)

const CheckoutPageTwo = (location) => {
  return (
    <Layout>
      <Elements stripe={stripePromise}>
        <section className="free-trial-area pb-100 bg-f6f8f9">
          <div className="container">
            <div className="free-trial-content">
              <h1>Checkout</h1>
              <ProtectedCheckoutForm location={location} />
            </div>
          </div>
        </section>
      </Elements>
    </Layout>
  )
}

export default CheckoutPageTwo
